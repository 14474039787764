import { get } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { fetchTitle, trackRV } from '../../../helpers/general';
import Container from '../../atoms/Container/Container';
import Accordion from '../../molecules/AccordionAlt/Accordion';
import ProductDetails from '../../molecules/ProductDetails/ProductDetails';
import ProductImages from '../../molecules/ProductImages/ProductImages';
// import Reviews from '../../organisms/Reviews/Reviews';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import recommend from '@algolia/recommend';
import { useRecommendations } from '@algolia/recommend-react';
import SimilarProducts from 'components/molecules/SimilarProducts/SimilarProducts';
import { graphql, useStaticQuery } from 'gatsby';
import ProductInformation from '../../molecules/ProductInformation/ProductInformation';
import ReviewSection from '../ReviewSection/ReviewSection';
import * as pcStyles from './Product.module.scss';
import * as mpsStyles from './Product.mps.module.scss';
import { useLocation } from '@reach/router';
import UserReview from 'components/atoms/UserReview/UserReview';
import { bcApi } from 'helpers/bigcommerce';
import { useQuery } from '@tanstack/react-query';
import { useProductStore } from 'stores/product';
import { Helmet } from 'react-helmet';
import { getDate, stripHtmlTags, storeStyle, isMadPawsStore } from '../../../common/util';
import useSegment from 'hooks/useSegment';

const recommendClient = recommend(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
);
const indexName = process.env.GATSBY_ALGOLIA_INDEX_NAME;

const styles = storeStyle({ mpsStyles, pcStyles });
const ProductPageTemplate = ({ data }) => {
  // const { width } = useWindowDimensions();
  const { track } = useSegment();

  const [init, setInit] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [ymal, setYmal] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const { activeVariant } = useProductStore(state => state);
  const { href, pathname } = useLocation();
  // const [rvCount, setRvCount] = useState(7);
  const sliderRef = useRef();
  if (data && 'entityId' in data) {
    trackRV(data.entityId);
  }

  const { data: updatedProduct, isLoading } = useQuery(
    ['single_product', data.entityId],
    async () =>
      bcApi(`catalog/products/${data.entityId}?include=variants,modifiers,custom_fields`, 'GET'),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  );
  const { recommendations } = useRecommendations({
    model: 'related-products',
    recommendClient,
    indexName,
    objectIDs: [String(data.entityId)],
    // objectIDs: ["3392"],
    maxRecommendations: 10
  });

  useEffect(() => {
    const pathSegments = pathname
      .split('/')
      .filter(segment => segment !== '')
      .join('_');
    if (pathSegments) {
      const scriptId = 'area10-script';
      const existingScript = document.getElementById(scriptId);
      // Remove existing script if it exists
      if (existingScript) {
        existingScript.parentNode.removeChild(existingScript);
      }
      const script = document.createElement('script');
      script.src = `//limitless.cmax.ai/petchemist.com.au/kronos/js/article_${pathSegments}.js?${getDate(
        false
      )}`;
      script.async = true;
      script.defer = true;
      script.id = scriptId;
      document.body.appendChild(script);
    }

    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    }
  }, []);

  useEffect(() => {
    // Set Breadcrumbs

    if (data && breadcrumbs.length === 0 && data.categories.length > 0) {
      const breadcrumbRaw = {};
      let breadcrumbStart = 0;
      let breadcrumbData = [{ link: '/', label: '' }];
      data.categories.map(cat => {
        if (!('parent_id' in cat)) cat.parent_id = 0;
        breadcrumbRaw[cat.parent_id] = cat;
        return true;
      });
      while (breadcrumbStart !== false) {
        if (breadcrumbStart in breadcrumbRaw) {
          breadcrumbData.push({
            link: breadcrumbRaw[breadcrumbStart].url,
            label: breadcrumbRaw[breadcrumbStart].name
          });
          breadcrumbStart = breadcrumbRaw[breadcrumbStart].id;
        } else {
          breadcrumbStart = false;
        }
      }
      breadcrumbData.push({ label: fetchTitle(data) });
      setBreadcrumbs(breadcrumbData);
    }

    // Set you may also like
    if (data.related_products.length > 0 && data.related_products[0] !== -1 && ymal.length === 0) {
      setYmal(data.related_products);
    } else if (get(data, 'youMayAlsoLike', []).length > 0 && ymal.length === 0) {
      let randomIndex = 0;
      let randomiseProducts = data.youMayAlsoLike;
      let currentIndex = randomiseProducts.length;

      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [randomiseProducts[currentIndex], randomiseProducts[randomIndex]] = [
          randomiseProducts[randomIndex],
          randomiseProducts[currentIndex]
        ];
      }

      if (randomiseProducts.length === 0) randomiseProducts = false;
      setYmal(randomiseProducts);
    }
  }, [data, breadcrumbs, ymal]);

  const salePriceDiscount = useMemo(() => {
    const activeData = data?.variants.find(variant => variant.entityId === activeVariant);
    const salePrice = activeData?.calculated_price;
    const price = activeData?.price;
    if (salePrice > 0 && salePrice !== price) {
      return Math.round(((price - salePrice) / price) * 100);
    }
    return null;
  }, [data, activeVariant]);

  const sortedImages = useMemo(() => {
    if (data?.images) {
      const imagesObj = [...data.images];

      // Sort based on is_thumbnail property
      imagesObj.sort((a, b) => (a.is_thumbnail ? -1 : b.is_thumbnail ? 1 : 0));

      if (activeVariant && data?.variants?.length > 1) {
        const findVariant = data?.variants?.find(variant => variant.entityId === activeVariant);

        if (findVariant?.image_url) {
          const thumbnailIndex = imagesObj.findIndex(image => image.is_thumbnail);
          if (thumbnailIndex !== -1) {
            // Replace the thumbnail image object with variant's image details
            imagesObj[thumbnailIndex] = {
              ...imagesObj[thumbnailIndex], // Use previous properties
              url_standard: findVariant.image_url,
              url_zoom: findVariant.image_url,
              url_thumbnail: findVariant.image_url
            };
          }
        }
      }

      return imagesObj;
    } else {
      return [];
    }
  }, [data, activeVariant]);

  const productMetaData = useStaticQuery(graphql`
    query {
      allBuilderModels {
        onePdpBottomSection {
          data {
            faq
            ratingBanner
          }
        }
      }
      allBrands: allBigCommerceBrands {
        edges {
          node {
            id
            bigcommerce_id
            name
            custom_url {
              url
            }
          }
        }
      }
    }
  `);

  const faq = productMetaData?.allBuilderModels?.onePdpBottomSection?.data?.faq;
  const review = productMetaData?.allBuilderModels?.onePdpBottomSection?.data?.ratingBanner;
  const brandData = productMetaData?.allBrands.edges.find(
    ({ node }) => node.bigcommerce_id === data.brand_id
  );
  
  useEffect(() => {
    if (!init && data) {
      setInit(true);
      const isMedication = data.custom_fields?.find(
        field => field.name === 'Product Type' && field.value === 'Medication'
      );
      track('Product Viewed', {
        brand: data.brand_id,
        affiliation: isMadPawsStore() ? 'MPS' : 'PC',
        name: data.name,
        price: data.calculate_price,
        product_id: data.entityId,
        sku: data.sku,
        product_type: !!isMedication ? 'Medication' : 'OTC',
        url: data.custom_url?.url,
        image: data.images?.find(img => img.is_thumbnail)?.url_standard
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, data]);

  const isOutOfStock = data.inventory_tracking !== 'none' && data.inventory_level === 0;

  const googleShoppingData = () => {
    if (data.variants.length > 1) {
      return [
        {
          '@context': 'https://schema.org/',
          '@type': 'ProductGroup',
          name: data.name,
          description: stripHtmlTags(data.description),
          url: href,
          brand: {
            '@type': 'Brand',
            name: brandData?.node.name
          },
          productGroupID: data.parent?.id,
          variesBy: ['https://schema.org/size'],
          hasVariant: data.variants.map(p => {
            return {
              '@type': 'Product',
              sku: p.sku,
              gtin14: p.gtin,
              image: p.image_url || data.images.map(i => i.url_standard),
              name: `${data.name} (${p.option_values[0]?.label})`,
              description: '',
              size: p.option_values[0]?.label,
              offers: {
                '@type': 'Offer',
                url: href,
                priceCurrency: 'AUD',
                price: p.calculated_price,
                itemCondition: 'https://schema.org/NewCondition',
                availability:
                  p.inventory_level === 0
                    ? 'https://schema.org/OutOfStock'
                    : 'https://schema.org/InStock',
                shippingDetails: {
                  '@context': 'https://schema.org/',
                  '@type': 'OfferShippingDetails',
                  '@id': '#shipping_policy',
                  shippingDestination: {
                    '@type': 'DefinedRegion',
                    addressCountry: 'AUD'
                  },
                  deliveryTime: {
                    '@type': 'ShippingDeliveryTime',
                    handlingTime: {
                      '@type': 'QuantitativeValue',
                      minValue: 0,
                      maxValue: 5,
                      unitCode: 'DAY'
                    },
                    transitTime: {
                      '@type': 'QuantitativeValue',
                      minValue: 1,
                      maxValue: 14,
                      unitCode: 'DAY'
                    }
                  }
                },
                hasMerchantReturnPolicy: {
                  '@context': 'http://schema.org/ ',
                  '@type': 'MerchantReturnPolicy',
                  '@id': '#return_policy',
                  applicableCountry: 'AUS',
                  returnPolicyCategory: 'https://petchemist.com.au/returns-and-refunds',
                  merchantReturnDays: 14,
                  returnMethod: 'https://schema.org/ReturnByMail',
                  returnFees: 'https://schema.org/FreeReturn'
                }
              }
            };
          }),
          review: {
            '@type': 'Review',
            reviewRating: {
              '@type': 'Rating',
              ratingValue: review.productReviews,
              bestRating: 5
            },
            author: {
              '@type': 'Person',
              name: review.fromName
            }
          },
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: 4.4,
            reviewCount: 89
          }
        },
        {
          '@context': 'https://schema.org/',
          '@type': 'OfferShippingDetails',
          '@id': '#shipping_policy',
          shippingDestination: {
            '@type': 'DefinedRegion',
            addressCountry: 'AUD'
          },
          deliveryTime: {
            '@type': 'ShippingDeliveryTime',
            handlingTime: {
              '@type': 'QuantitativeValue',
              minValue: 0,
              maxValue: 5,
              unitCode: 'DAY'
            },
            transitTime: {
              '@type': 'QuantitativeValue',
              minValue: 1,
              maxValue: 14,
              unitCode: 'DAY'
            }
          }
        },
        {
          '@context': 'http://schema.org/ ',
          '@type': 'MerchantReturnPolicy',
          '@id': '#return_policy',
          applicableCountry: 'AUS',
          returnPolicyCategory: 'https://petchemist.com.au/returns-and-refunds',
          merchantReturnDays: 14,
          returnMethod: 'https://schema.org/ReturnByMail',
          returnFees: 'https://schema.org/FreeReturn'
        }
      ];
    }
    return {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: data.name,
      image: data.images.map(i => i.url_standard),
      description: stripHtmlTags(data.description),
      sku: data.sku,
      mpn: data.mpn,
      brand: {
        '@type': 'Brand',
        name: brandData?.node.name
      },
      review: {
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: review.productReviews,
          bestRating: 5
        },
        author: {
          '@type': 'Person',
          name: review.fromName
        }
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: 4.4,
        reviewCount: 89
      },
      offers: {
        '@type': 'Offer',
        url: href,
        priceCurrency: 'AUD',
        price: data.calculated_price,
        priceValidUntil: getDate(),
        itemCondition: 'https://schema.org/NewCondition',
        availability: !isOutOfStock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'
      }
    };
  };
  return (
    <div>
      {data && (
        <div>
          <Helmet>
            <script id="googleStructureData" type="application/ld+json">
              {JSON.stringify(googleShoppingData())}
            </script>
          </Helmet>
          <div className={styles.gradient}>
            <Container size="large">
              <div id="productDetails" className={styles.productTop}>
                <div className={styles.productTopEntry}>
                  <div>
                    <div className={styles.breadcrumbs}>
                      <Breadcrumbs crumbs={breadcrumbs} />
                    </div>
                    <div className={styles.productContainer}>
                      <div
                        className={`${styles.productTopCol} ${styles.colThumbnails}`}
                        data-img-count={data ? data.images?.length || 0 : 0}
                      >
                        <ProductImages
                          ref={sliderRef}
                          activeIndex={activeIndex}
                          setActiveIndex={setActiveIndex}
                          sortedImages={sortedImages}
                          identifier="product-images"
                          type="thumbnails"
                        />
                      </div>
                      <div className={`${styles.productTopCol} ${styles.colImages}`}>
                        {!!salePriceDiscount && salePriceDiscount > 4 && (
                          <div className={styles.discount}>Save {salePriceDiscount}%</div>
                        )}

                        <ProductImages
                          ref={sliderRef}
                          product={data}
                          activeIndex={activeIndex}
                          setActiveIndex={setActiveIndex}
                          sortedImages={sortedImages}
                          identifier="product-images"
                          type="gallery"
                        />
                        {/* <ProductFlags data={data} /> */}
                      </div>
                    </div>
                  </div>

                  <div className={`${styles.productTopCol} ${styles.colDetails}`}>
                    <ProductDetails
                      variants={
                        isLoading ? data.variants : updatedProduct?.response?.data?.variants
                      }
                      modifiers={
                        isLoading ? data.modifiers : updatedProduct?.response?.data?.modifiers
                      }
                      recommendations={recommendations}
                      product={data}
                      brand={brandData}
                      type="full"
                    />
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <ProductInformation description={data.description} />
          {recommendations?.length > 0 && (
            <Container size="large">
              {/* TODO: REvert this */}
              <div>
                {/* <h4 className="mb-8">Frequently bought together</h4> */}
                {/* <FrequentProducts products={ymal} /> */}
              </div>
              <div className="my-8 pb-8">
                <h3 className={styles.similarHeader}>Similar products</h3>
                <SimilarProducts products={recommendations} />
              </div>
            </Container>
          )}

          <ReviewSection product={data} />
          <Container size="large">
            <section className={styles.Faqs}>
              <h4>Frequently asked questions</h4>
              {faq?.map((faq, index) => (
                <Accordion
                  key={index}
                  customStyle={styles}
                  type={'add'}
                  overrideSize={true}
                  title={faq.question}
                  defaultOpen={index === 0}
                >
                  <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                </Accordion>
              ))}
            </section>
          </Container>

          {/* <RecentlyViewed
            currentProductId={data.entityId}
            limit={rvCount}
            columns={rvCount}
            withContainer={true}
            title='Recently viewed products'
            styles={styles}
          /> */}
          <Container size="large">
            <div id="a10_kronos"></div>
          </Container>
          <Container size="large">
            <UserReview {...review} />
          </Container>
        </div>
      )}
    </div>
  );
};

export default ProductPageTemplate;
