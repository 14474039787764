import { graphql } from 'gatsby';
import React from 'react';
import { dissectTitle } from '../helpers/general';

import Layout from '../components/organisms/Layout/Layout';
import Product from '../components/organisms/Product/Product';
import Seo from '../components/organisms/Seo/Seo';

// //above inventory level
// options {
//   option_values {
//     id
//     is_default
//     label
//   }
//   id
//   display_name
//   type
// }

export const query = graphql`
  query ProductTemplateQuery($id: String!) {
    product: bigCommerceProducts(id: { eq: $id }) {
      id
      base_variant_id
      entityId: bigcommerce_id
      sku
      name
      price
      calculated_price
      retail_price
      sale_price
      map_price
      description
      brand_id
      weight
      categories
      page_title
      related_products
      warranty
      order_quantity_maximum
      meta_description
      mpn
      gtin
      custom_url {
        url
      }
      images {
        url_standard
        url_thumbnail
        url_zoom
        id
        description
        image_file
        is_thumbnail
      }
      modifiers {
        name
        id
        display_name
        required
        sort_order
        type
        option_values {
          id
          label
          option_id
          adjusters {
            price {
              adjuster
              adjuster_value
            }
          }
        }
      }
      variants {
        id
        image_url
        product_id
        inventory_level
        entityId: id
        calculated_price
        price
        sku
        gtin
        option_values {
          id
          label
          option_display_name
          option_id
        }
      }
      options {
        option_values {
          id
          is_default
          label
          sort_order
        }
        id
        display_name
        type
      }
      parent { id }
      inventory_level
      inventory_tracking
      inventory_warning_level
      availability
      availability_description
      reviews_count
      reviews_rating_sum
      custom_fields {
        name
        value
      }
    }

    categories: allBigCommerceCategories {
      edges {
        node {
          bigcommerce_id
          name
          parent_id
          sort_order
          custom_url {
            url
            is_customized
          }
        }
      }
    }
  }
`;

const ProductTemplate = props => {
  const { data, errors } = props;
  const product = data && data.product;
  product.ordergrooveData = props.pageContext.ordergrooveData
  // const relatedProducts = pageContext.relatedProducts;
  const productName = dissectTitle(product.name, ' (')[0];

  product.categories = product.categories
    .map(cat_id => {
      if (typeof cat_id === 'number') {
        const cat = data.categories.edges
          .map(a => a.node)
          .filter(cat => cat.bigcommerce_id === cat_id);
        return cat.length
          ? {
              id: cat[0].bigcommerce_id,
              name: cat[0].name,
              parent_id: cat[0].parent_id,
              sort_order: cat[0].sort_order,
              url: cat[0].custom_url?.url
            }
          : null;
      } else {
        return cat_id;
      }
    })
    .filter(a => a !== null);

  // product.related_products = relatedProducts;
  // product.youMayAlsoLike = data.youMayAlsoLike.edges;
  // product.detailedPrices = data.productPrices.edges[0].node;

  return (
    <Layout>
      {errors && <Seo title="GraphQL Error" />}
      {product && (
        <Seo
          title={product.page_title || productName}
          description={product.meta_description || ''}
        />
      )}

      {/* {errors && (
            <Container>
              <GraphQLErrorList errors={errors} />
            </Container>
        )} */}

      {product && <Product data={product} />}
    </Layout>
  );
};

export default ProductTemplate;
